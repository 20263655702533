import { AddonInterface } from "@/ts/interfaces/Checkout/AddonInterface";
import { ChargeInterface } from "@/ts/interfaces/Checkout/ChargeInterface";
import { GradeInterface } from "@/ts/interfaces/Checkout/GradeInterface";
import { PlanInterface } from "@/ts/interfaces/Checkout/PlanInterface";
import { ProductFamilyInterface } from "@/ts/interfaces/Checkout/ProductFamilyInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ProgramState {
    productFamily: ProductFamilyInterface | null;
    plan: PlanInterface | null;
    charge: ChargeInterface | null;
    addon: AddonInterface | null;
    grade: GradeInterface | null;
    coupon: string | null;
    checkoutTotalAmount: number;
}

const initialState: ProgramState = {
    productFamily: null,
    plan: null,
    charge: null,
    addon: null,
    grade: null,
    coupon: null,
    checkoutTotalAmount: 0
};

const programSlice = createSlice({
    name: "program",
    initialState,
    reducers: {
        setProductFamily(
            state,
            action: PayloadAction<ProductFamilyInterface | null>
        ) {
            state.productFamily = action.payload;
        },
        setPlan(state, action: PayloadAction<PlanInterface | null>) {
            state.plan = action.payload;
        },
        setCharge(state, action: PayloadAction<ChargeInterface | null>) {
            state.charge = action.payload;
        },
        setAddon(state, action: PayloadAction<AddonInterface | null>) {
            state.addon = action.payload;
        },
        setGrade(state, action: PayloadAction<GradeInterface | null>) {
            state.grade = action.payload;
        },
        setCoupon(state, action: PayloadAction<string | null>) {
            state.coupon = action.payload;
        },
        setCheckoutTotalAmount(state, action: PayloadAction<number>) {
            state.checkoutTotalAmount = action.payload;
        },
        resetProgramState() {
            return initialState;
        }
    }
});

export const {
    setProductFamily,
    setPlan,
    setCharge,
    setAddon,
    setGrade,
    resetProgramState,
    setCoupon,
    setCheckoutTotalAmount
} = programSlice.actions;

export default programSlice.reducer;
