import {
    fetchProductFamiliesApi,
    postAddPremiumServiceApi,
    postAddSubjectChangesApi,
    postCheckoutStateApi,
    postCreateCbSubscriptionApi,
    postCreatePaymentIntentApi,
    postValidateCouponApi
} from "@/api/checkoutApi";
import { RootState } from "@/store";
import { ProductFamilyInterface } from "@/ts/interfaces/Checkout/ProductFamilyInterface";
import { AddPremiumServiceApiInterface } from "@/ts/interfaces/Checkout/api/AddPremiumServiceApiInterface";
import { AddSubjectChangeApiInterface } from "@/ts/interfaces/Checkout/api/AddSubjectChangeApiInterface";
import { CreatePIApiInterface } from "@/ts/interfaces/Checkout/api/CreatePIApiInterface";
import { CreateSubscriptionApiInterface } from "@/ts/interfaces/Checkout/api/CreateSubscriptionApiInterface";
import { ProductFamilyApiInterface } from "@/ts/interfaces/Checkout/api/ProductFamilyApiInterface";
import { ValidateCouponApiInterface } from "@/ts/interfaces/Checkout/api/ValidateCouponApiInterface";
import { ValidateCouponResponseInterface } from "@/ts/interfaces/Checkout/api/ValidateCouponResponseInterface";
import { mapProductFamiliesApiData } from "@/utils/checkoutDataMapper";
import { sortItems } from "@/utils/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

export const fetchProductFamilies = createAsyncThunk<
    {
        productFamilies: ProductFamilyInterface[];
        productFamiliesResponse: ProductFamilyApiInterface[];
    },
    {
        productFamilyUrlsByCountry: Record<string, string>;
        country: string;
        lang: string;
    },
    { rejectValue: string }
>(
    "fetchProductFamilies",
    async (
        { productFamilyUrlsByCountry, country, lang },
        { rejectWithValue }
    ) => {
        const updatedCountry = country === "international" ? "AU" : country;
        const productFamilyUrl =
            Object.entries(productFamilyUrlsByCountry).find(
                ([countryCode]) => countryCode === updatedCountry
            )?.[1] ?? "";

        const productFamilyUrlBasedOnLang = productFamilyUrl.replace(
            /\.json$/,
            lang !== "en" ? `_${lang}.json` : ".json"
        );

        try {
            const response = await fetchProductFamiliesApi(
                productFamilyUrlBasedOnLang
            );

            const convertedProductFamilies = mapProductFamiliesApiData(
                sortItems<ProductFamilyApiInterface>(
                    response?.data ?? [],
                    "order",
                    ["addons", "plans"]
                )
            );

            const payload = {
                productFamilies: convertedProductFamilies,
                productFamiliesResponse: response?.data ?? []
            };

            return payload;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }
            console.error("Unexpected error:", error);
            return rejectWithValue(
                "An unexpected error occurred while fetching product families."
            );
        }
    }
);

export const postCheckoutState = createAsyncThunk<
    { token: string },
    void,
    { state: RootState }
>("postCheckoutState", async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const { program, user } = state.checkout;
    const { checkoutToken } = state.checkout.userSettings;

    try {
        const response = await postCheckoutStateApi(
            {
                token_info: program,
                username: user.parentUser.email,
                type: "checkout"
            },
            checkoutToken
        );
        return { token: response.data.data.token };
    } catch (error) {
        if (error instanceof AxiosError) {
            return rejectWithValue(error.message);
        }
        console.error("Unexpected error:", error);
        return rejectWithValue(
            "An unexpected error occurred while posting checkout state."
        );
    }
});

export const postCreatePaymentIntent = createAsyncThunk<
    { id: string; gateway_account_id: string },
    CreatePIApiInterface,
    { state: RootState }
>("postCreatePaymentIntent", async (data, { rejectWithValue }) => {
    try {
        const {
            data: { data: responseData }
        } = await postCreatePaymentIntentApi(data);
        return responseData;
    } catch (error) {
        if (error instanceof AxiosError) {
            return rejectWithValue(error.message);
        }

        console.error("Unexpected error:", error);
        return rejectWithValue(
            "An unexpected error occurred while creating payment intent."
        );
    }
});

export const createSubscription = createAsyncThunk<
    void,
    CreateSubscriptionApiInterface,
    { state: RootState }
>("createSubscription", async (data, { rejectWithValue }) => {
    try {
        const {
            data: { data: responseData }
        } = await postCreateCbSubscriptionApi(data);
        return responseData;
    } catch (error) {
        if (error instanceof AxiosError) {
            return rejectWithValue(error.message);
        }

        console.error("Unexpected error:", error);
        return rejectWithValue(
            "An unexpected error occurred while creating the subscription."
        );
    }
});

export const addAddon = createAsyncThunk<
    void,
    AddPremiumServiceApiInterface,
    { state: RootState }
>("addAddon", async (data, { rejectWithValue }) => {
    try {
        const {
            data: { data: responseData }
        } = await postAddPremiumServiceApi<void>(data);
        return responseData;
    } catch (error) {
        if (error instanceof AxiosError) {
            return rejectWithValue(error.message);
        }

        console.error("Unexpected error:", error);
        return rejectWithValue(
            "An unexpected error occurred while adding the addon."
        );
    }
});

export const addSubjectChanges = createAsyncThunk<
    void,
    AddSubjectChangeApiInterface,
    { state: RootState }
>("addSubjectChanges", async (data, { rejectWithValue }) => {
    try {
        const {
            data: { data: responseData }
        } = await postAddSubjectChangesApi<void>(data);
        return responseData;
    } catch (error) {
        if (error instanceof AxiosError) {
            return rejectWithValue(error.message);
        }

        console.error("Unexpected error:", error);
        return rejectWithValue(
            "An unexpected error occurred while adding subject changes."
        );
    }
});

export const createPaymentIntentForAddon = createAsyncThunk<
    { id: string; gateway_account_id: string },
    AddPremiumServiceApiInterface,
    { state: RootState }
>("createPaymentIntentForAddon", async (data, { rejectWithValue }) => {
    try {
        const {
            data: { data: responseData }
        } = await postAddPremiumServiceApi<{
            id: string;
            gateway_account_id: string;
        }>(data);
        return responseData;
    } catch (error) {
        if (error instanceof AxiosError) {
            return rejectWithValue(error.message);
        }

        console.error("Unexpected error:", error);
        return rejectWithValue(
            "An unexpected error occurred while creating the payment intent for the addon."
        );
    }
});

export const createPaymentIntentForSubjectChanges = createAsyncThunk<
    { id: string; gateway_account_id: string },
    AddSubjectChangeApiInterface,
    { state: RootState }
>("createPaymentIntentForSubjectChanges", async (data, { rejectWithValue }) => {
    try {
        const {
            data: { data: responseData }
        } = await postAddSubjectChangesApi<{
            id: string;
            gateway_account_id: string;
        }>(data);
        return responseData;
    } catch (error) {
        if (error instanceof AxiosError) {
            return rejectWithValue(error.message);
        }

        console.error("Unexpected error:", error);
        return rejectWithValue(
            "An unexpected error occurred while creating the payment intent for subject changes."
        );
    }
});

export const validateCoupon = createAsyncThunk<
    ValidateCouponResponseInterface,
    ValidateCouponApiInterface,
    { state: RootState }
>("validateCoupon", async (data, { rejectWithValue }) => {
    try {
        const response = await postValidateCouponApi(data);
        const { data: responseData } = response.data;
        return responseData;
    } catch (error) {
        if (error instanceof AxiosError) {
            return rejectWithValue(error.response?.data.message as string);
        }

        console.error("Unexpected error:", error);
        return rejectWithValue(
            "An unexpected error occurred while validating the coupon."
        );
    }
});
