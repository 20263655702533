/* eslint-disable */
// @ts-nocheck
import "react-datepicker/dist/react-datepicker.min.css";
import { createRoot } from "react-dom/client";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { createInertiaApp } from "@inertiajs/react";
import AppProvider from "@providers/AppProvider";
import theme from "@theme";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import * as process from "process";

import "./i18next";
// DO NOT REMOVE - linked to static & chargebee image.
import "./imports/imageImports";

const appName =
    process.env &&
    process.env.APP_NAME !== undefined &&
    process.env.APP_NAME !== null
        ? process.env.APP_NAME
        : "Homeschooling Australia - Home School Programs | Euka";

if (
    process.env &&
    process.env.DATADOG_APPLICATION_ID !== undefined &&
    process.env.DATADOG_APPLICATION_ID !== null
) {
    try {
        datadogRum.init({
            applicationId: process.env.DATADOG_APPLICATION_ID,
            clientToken: process.env.DATADOG_CLIENT_TOKEN,
            site: "datadoghq.com",
            service: `portal-ui-${process.env.PIPELINE_ENVIRONMENT}`,
            env: process.env.PIPELINE_ENVIRONMENT,
            // Specify a version number to identify the deployed version of your application in Datadog.
            version: process.env.UI_VERSION,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "mask-user-input",
            allowedTracingUrls: [process.env.APP_URL]
        });

        datadogLogs.init({
            clientToken: process.env.DATADOG_CLIENT_TOKEN,
            site: "datadoghq.com",
            forwardErrorsToLogs: true,
            forwardConsoleLogs: "all",
            forwardReports: "all",
            sessionSampleRate: 100
        });

        datadogLogs.logger.info("Initialised Datadog logger successfully");
    } catch (exception) {
        console.log("DD Failed!", { error: exception });
    }
}
createInertiaApp({
    title: title => `${title} - ${appName}`,
    resolve: name =>
        resolvePageComponent(
            `./inertia-pages/${name}.tsx`,
            import.meta.glob("./inertia-pages/**/*.tsx")
        ),
    setup({ el, App, props }) {
        const root = createRoot(el);

        const renderAllApps = () => {
            return (
                <AppProvider>
                    <App {...props} />
                </AppProvider>
            );
        };

        root.render(renderAllApps());
    },
    progress: {
        color: theme["colors"]["burntYellow"][500]
    }
});
