import { i18next } from "@/i18next";
import { AddonOfChargeApiInterface } from "@/ts/interfaces/Checkout/api/AddonOfChargeApiInterface";
import { UserInterface } from "@/ts/interfaces/Parent/UserInterface";

import { PLAN_TYPE } from "@ts/enums/PlanTypesEnum.ts";
import { AddonInterface } from "@ts/interfaces/Checkout/AddonInterface";
import { GradeInterface } from "@ts/interfaces/Checkout/GradeInterface";
import { PlanInterface } from "@ts/interfaces/Checkout/PlanInterface";
import { ProductFamilyInterface } from "@ts/interfaces/Checkout/ProductFamilyInterface";
import { UserCheckoutStateInterface } from "@ts/interfaces/Checkout/UserCheckoutStateInterface";
import { AddonApiInterface } from "@ts/interfaces/Checkout/api/AddonApiInterface";
import { GradeApiInterface } from "@ts/interfaces/Checkout/api/GradeApiInterface";
import { PlanApiInterface } from "@ts/interfaces/Checkout/api/PlanApiInterface.ts";
import { ProductFamilyApiInterface } from "@ts/interfaces/Checkout/api/ProductFamilyApiInterface.ts";

export const mapProductFamiliesApiData = (
    productFamilies: ProductFamilyApiInterface[]
): ProductFamilyInterface[] => {
    const data: ProductFamilyInterface[] = productFamilies.map(p => ({
        id: p.id,
        coverImage: `${process.env.ASSET_URL}/build/assets/${p.cover_image}`,
        description: p.grade_range_label,
        name: p.name,
        hasCharges: p.plans_contains_charges,
        plans: p.plans ? mapPlansApiData(p.plans) : [],
        gradeRangeLabel: p.grade_range_label,
        selectMembershipDescription: p.select_membership_description,
        grades: p.grades ? mapGradesApiData(p.grades) : []
    }));
    return data;
};

export const mapPlansApiData = (plans: PlanApiInterface[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getTitlePrice = (planPrice: any) => {
        const period: string = planPrice?.period;
        const billingCycle: number = planPrice?.billing_cycle;
        const numberOfInstalments: number = planPrice?.number_of_instalments;
        const nextPaymentInMonths: number = planPrice?.next_payment_in_months;
        const currency: string = planPrice?.currency;
        const price: number = planPrice?.price;
        let titlePeriodStr = "";
        let priceTitle = "";
        let priceSubTitle = "";
        let planDescription = "";
        let description = "";
        let planType = "";

        if (period === "year" && numberOfInstalments === 1) {
            titlePeriodStr = billingCycle === 0 ? i18next.t("per year") : "";
            priceTitle = `$${price / 100} ${currency}`;
            planDescription = i18next.t("Yearly Plan");
            description = i18next.t("Paid Upfront");
            planType = PLAN_TYPE.YEARLY;
        } else if (period === "year" && numberOfInstalments > 1) {
            titlePeriodStr =
                billingCycle === 0
                    ? i18next.t("every {{ nextPaymentInMonths }} months", {
                          nextPaymentInMonths
                      })
                    : "";
            priceTitle = `$${price / 100} ${currency}`;
            priceSubTitle = i18next.t(
                "Total yearly amount ${{ price }} {{ currency }}",
                { price: (price / 100) * numberOfInstalments, currency }
            );
            planDescription = i18next.t(
                "Yearly Plan ({{ installments }} x Instalments)",
                { installments: planPrice?.number_of_instalments }
            );
            description = i18next.t(
                "Paid over {{ installments }} instalments",
                {
                    installments: planPrice?.number_of_instalments
                }
            );
            planType = PLAN_TYPE.INSTALLMENT;
        } else if (period === "month" && numberOfInstalments > 1) {
            titlePeriodStr =
                billingCycle === 0
                    ? i18next.t("every {{ nextPaymentInMonths }} months", {
                          nextPaymentInMonths
                      })
                    : "";
            priceTitle = `$${price / 100} ${currency}`;
            planDescription = i18next.t("Term Plan");
            description = "";
            planType = PLAN_TYPE.QUARTERLY;
        } else if (period === "month" && numberOfInstalments === 1) {
            titlePeriodStr = billingCycle === 0 ? i18next.t("every month") : "";
            priceTitle = `$${price / 100} ${currency}`;
            planDescription = i18next.t("Monthly Plan");
            description = "";
            planType = PLAN_TYPE.MONTHLY;
        }

        return {
            titlePeriodStr,
            priceTitle,
            planDescription,
            description,
            planType,
            priceSubTitle,
            numberOfInstalments,
            nextPaymentInMonths
        };
    };

    const data: PlanInterface[] = plans.map(p => {
        const {
            description,
            planDescription,
            numberOfInstalments,
            planType,
            priceTitle,
            titlePeriodStr,
            priceSubTitle,
            nextPaymentInMonths
        } = getTitlePrice(p.price);

        return {
            id: p.id,
            name: p.name,
            priceId: p.price!.price_id,
            currency: p.price!.currency,
            description,
            planDescription,
            type: planType,
            numberOfInstalments,
            features: constructFeatures(p.metadata),
            isHighlighted: p.is_highlighted,
            price: p.price!.price / 100,
            highlightedText: p.highlighted_text,
            period: p.price!.period,
            reducedPrice: 0,
            title: priceTitle,
            priceSubTitle,
            addons: p.addons
                ? mapAddonsApiData(p.addons, {
                      planType,
                      nextPaymentInMonths,
                      numberOfInstalments
                  })
                : null,
            targetGradeId: p.target_grade ? p.target_grade.id : null,
            targetGradeName: p.target_grade ? p.target_grade.name : undefined,
            titlePeriod: titlePeriodStr,
            order: p.order,
            crossedPriceLabel:
                p.price!.period === "year" &&
                p.price!.next_payment_in_months === 12 &&
                p.price!.yearly_price
                    ? `$${p.price!.yearly_price} ${p.price!.currency}`
                    : undefined,
            isPreSelectedPlan: p.is_selected,
            charges: p.charges
                ? {
                      addons: p.charges.addon
                          ? mapAddonsOfChargeApiData(p.charges.addon)
                          : null
                  }
                : null
        };
    });
    return data;
};

export const mapGradesApiData = (grades: GradeApiInterface[]) => {
    const data: GradeInterface[] = grades.map(g => ({
        id: g.id,
        name: g.name,
        shortName: g.short_name,
        year: g.year,
        upgradeAllowed: g.upgrade_allowed
    }));
    return data;
};

export const mapAddonsOfChargeApiData = (
    addons: AddonOfChargeApiInterface[]
) => {
    const data: AddonInterface[] = addons.map(a => ({
        id: a.price_id,
        name: a.name,
        priceId: a.price_id,
        description: a.description,
        coverImage: `${process.env.ASSET_URL}/build/assets/${a.cover_image}`,
        isHighlighted: a.is_highlighted,
        highlightedText: a.highlighted_text,
        price: a.price / 100,
        isIncluded: a.price <= 0,
        currency: a.currency,
        priceLabel: `${a.price / 100} ${a.currency}`,
        billingFrequency: "per year",
        crossedPriceLabel: `${a.discount_price / 100} ${a.currency}`,
        isBulkAddon: a.is_bulk_addon,
        entitlementsIds: a.entitlements_ids ?? [],
        entitlementsNames: a.entitlements_names ?? [],
        features: constructFeatures(a.metadata),
        order: a.order
    }));
    return data;
};

export const mapAddonsApiData = (
    addons: AddonApiInterface[],
    planInfo: {
        planType: string;
        nextPaymentInMonths: number;
        numberOfInstalments: number;
    }
) => {
    const { planType, nextPaymentInMonths, numberOfInstalments } = planInfo as {
        planType: PLAN_TYPE;
        nextPaymentInMonths: number;
        numberOfInstalments: number;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getTitlePrice = (addonPrice: any) => {
        const priceSubTitle =
            planType === PLAN_TYPE.INSTALLMENT
                ? i18next.t("every {{ nextPaymentInMonths }} months", {
                      nextPaymentInMonths
                  })
                : "";

        const priceFooterText1 =
            planType === PLAN_TYPE.INSTALLMENT
                ? i18next.t("Paid in {{ numberOfInstalments }} instalments", {
                      numberOfInstalments
                  })
                : "";

        const priceFooterText2 =
            planType === PLAN_TYPE.INSTALLMENT
                ? i18next.t("Total amount ${{ price }} {{ currency }}", {
                      price: (addonPrice.price / 100) * numberOfInstalments,
                      currency: addonPrice.currency
                  })
                : "";

        return {
            priceSubTitle,
            priceFooterText1,
            priceFooterText2
        };
    };

    const data: AddonInterface[] = addons.map(a => {
        const { priceFooterText1, priceFooterText2, priceSubTitle } =
            getTitlePrice(a.price);

        return {
            id: a.id,
            name: a.name,
            priceId: a.price.price_id,
            description: a.description,
            coverImage: `${process.env.ASSET_URL}/build/assets/${a.cover_image}`,
            isHighlighted: a.is_highlighted,
            highlightedText: a.highlighted_text,
            price: a.price.price / 100,
            isIncluded: a.price.price <= 0,
            currency: a.price.currency,
            priceLabel: `${a.price.price / 100} ${a.price.currency}`,
            billingFrequency:
                a.price.period === "year" ? "per year" : "per term",
            crossedPriceLabel: `${a.price.discount_price / 100} ${
                a.price.currency
            }`,
            isBulkAddon: a.is_bulk_addon,
            features: constructFeatures(a.metadata),
            order: a.order,
            priceFooterText1,
            priceFooterText2,
            priceSubTitle
        };
    });
    return data;
};

const constructFeatures = (metadata: string) =>
    metadata
        ? (
              (JSON.parse(metadata).features as {
                  name: string;
                  isActive: boolean;
              }[]) ?? []
          ).map(f => ({
              id: Math.random(),
              text: f.name,
              isAvailable: f.isActive
          }))
        : [];

export const mapTokenDataToCheckoutAtom = (
    {
        STUDENT_SELECTION_PAGE,
        CREATE_NEW_STUDENT_PAGE,
        PRODUCT_FAMILY_SELECT_PAGE,
        PLAN_SELECT_PAGE,
        ADDON_SELECT_PAGE,
        USER_INFO_PAGE,
        nextAttemptPage
    }: UserCheckoutStateInterface,
    productFamilies: ProductFamilyInterface[],
    students: UserInterface[]
) => {
    const selectedStudent =
        students?.find(
            sF =>
                sF.id ===
                (STUDENT_SELECTION_PAGE ? STUDENT_SELECTION_PAGE.id : null)
        ) ?? null;
    const student = CREATE_NEW_STUDENT_PAGE
        ? {
              firstName: CREATE_NEW_STUDENT_PAGE.firstName ?? null,
              lastName: CREATE_NEW_STUDENT_PAGE.lastName ?? null
          }
        : null;
    const productFamily =
        productFamilies.find(
            pF =>
                pF.id ===
                (PRODUCT_FAMILY_SELECT_PAGE
                    ? PRODUCT_FAMILY_SELECT_PAGE.id
                    : null)
        ) ?? null;
    const grade = productFamily
        ? (productFamily.grades.find(
              g =>
                  g.name ===
                  (PRODUCT_FAMILY_SELECT_PAGE
                      ? PRODUCT_FAMILY_SELECT_PAGE.gradeName
                      : null)
          ) ?? null)
        : null;
    const plan = productFamily
        ? (productFamily.plans.find(
              p => p.id === (PLAN_SELECT_PAGE ? PLAN_SELECT_PAGE.id : null)
          ) ?? null)
        : null;
    const addon =
        plan && plan.addons
            ? (plan.addons.find(
                  a =>
                      a.id === (ADDON_SELECT_PAGE ? ADDON_SELECT_PAGE.id : null)
              ) ??
              // looking for charges
              plan.charges?.addons?.find(
                  a =>
                      a.id === (ADDON_SELECT_PAGE ? ADDON_SELECT_PAGE.id : null)
              ) ??
              null)
            : null;

    return {
        user: {
            firstName: USER_INFO_PAGE.firstName,
            email: USER_INFO_PAGE.email,
            receiveOfferNews: USER_INFO_PAGE.receiveOfferNews
        },
        productFamily,
        plan,
        grade,
        addon,
        nextAttemptPage,
        charge: null,
        selectedStudent,
        student
    };
};

export const mapQueryParamsDataToCheckoutState = (
    queryParams: {
        productFamilyId: string | null;
        gradeName: string | null;
        planId: string | null;
        addonId: string | null;
    },
    productFamilies: ProductFamilyInterface[]
) => {
    const { productFamilyId, gradeName, planId, addonId } = queryParams;

    const productFamily =
        productFamilies.find(p => p.id === productFamilyId) ?? null;
    const grade = productFamily
        ? (productFamily.grades.find(g => g.name === gradeName) ?? null)
        : null;
    const plan = productFamily
        ? (productFamily.plans.find(p => p.id === planId) ?? null)
        : null;
    const addon =
        plan && plan.addons
            ? (plan.addons.find(a => a.id === addonId) ?? null)
            : null;
    return { productFamily, grade, plan, addon };
};
