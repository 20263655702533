import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PageQueryParamsState {
    family: string | null;
    grade: string | null;
    plan: string | null;
    addon: string | null;
    coupon: string | null;
    selectedCountry: string | null;
    selectedState: string | null;
    skip: string | null;
    origin: string | null;
    selectedLang: string | null;
}

const initialState: PageQueryParamsState = {
    family: null,
    grade: null,
    plan: null,
    addon: null,
    coupon: null,
    selectedCountry: null,
    selectedState: null,
    skip: null,
    origin: null,
    selectedLang: null
};

const pageQueryParamsSlice = createSlice({
    name: "pageQueryParams",
    initialState,
    reducers: {
        setQueryParam(
            state,
            action: PayloadAction<{
                key: keyof PageQueryParamsState;
                value: string | null;
            }>
        ) {
            const { key, value } = action.payload;
            state[key] = value;
        },
        resetQueryParamsState() {
            return initialState;
        }
    }
});

export const { setQueryParam, resetQueryParamsState } =
    pageQueryParamsSlice.actions;

export default pageQueryParamsSlice.reducer;
