import pageQueryParamsReducer from "@/store/features/checkout/slices/pageQueryParamsSlice";
import paymentReducer from "@/store/features/checkout/slices/paymentSlice";
import programReducer from "@/store/features/checkout/slices/programSlice";
import userSettingsReducer from "@/store/features/checkout/slices/userSettingsSlice";
import userReducer from "@/store/features/checkout/slices/userSlice";
import useCheckoutDispatch from "@/store/features/checkout/useCheckoutDispatch";
import useCheckoutSelector from "@/store/features/checkout/useCheckoutSelector";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";

const userSettingsPersistConfig = {
    key: "userSettings",
    storage: sessionStorage,
    blacklist: [
        "parentUserUuid",
        "isNewsletterSubEnabled",
        "canShowExistingUserPage",
        "checkoutToken",
        "studentFlow",
        "isProvidedQueryParams",
        "productFamiliesRequestStatus",
        "checkoutStateRequestStatus",
        "productFamiliesResponse"
    ]
};

const programPersistConfig = {
    key: "program",
    storage: sessionStorage,
    blacklist: ["coupon", "checkoutTotalAmount"]
};

const userPersistConfig = {
    key: "user",
    storage: sessionStorage
};

const persistedUserSettingsReducer = persistReducer(
    userSettingsPersistConfig,
    userSettingsReducer
);

const persistedProgramReducer = persistReducer(
    programPersistConfig,
    programReducer
);

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

const checkoutReducer = combineReducers({
    userSettings: persistedUserSettingsReducer,
    program: persistedProgramReducer,
    user: persistedUserReducer,
    pageQueryParams: pageQueryParamsReducer,
    payment: paymentReducer
});

export default checkoutReducer;

export { useCheckoutDispatch, useCheckoutSelector };
