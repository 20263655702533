export const getLocalStorageItem = <T>(key: string): T | null => {
    try {
      const serializedValue = localStorage.getItem(key);
      if (serializedValue === null) {
        return null;
      }
      return JSON.parse(serializedValue);
    } catch (error) {
      console.error(`Error retrieving "${key}" from localStorage: ${error}`);
      return null;
    }
};

export const setLocalStorageItem = <T>(key: string, value: T): void => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error(`Error storing "${key}" in localStorage: ${error}`);
    }
};

export const removeLocalStorageItem = (key: string): void => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error(`Error removing "${key}" from localStorage: ${error}`);
    }
};
