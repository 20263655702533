import { CheckoutUserInterface } from "@/ts/interfaces/Checkout/CheckoutUserInterface";
import { CreateStudentFormDataInterface } from "@/ts/interfaces/Enrol/StudentEntrolInterface";
import { UserInterface } from "@/ts/interfaces/Parent/UserInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserState {
    parentUser: CheckoutUserInterface;
    student: CreateStudentFormDataInterface | null;
    selectedStudent: UserInterface | null;
}

const initialState: UserState = {
    selectedStudent: null,
    student: null,
    parentUser: {
        firstName: "",
        email: "",
        receiveOfferNews: false
    }
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setSelectedStudent(state, action: PayloadAction<UserInterface | null>) {
            state.selectedStudent = action.payload;
        },
        setStudent(
            state,
            action: PayloadAction<CreateStudentFormDataInterface | null>
        ) {
            state.student = action.payload;
        },
        setParentUser(state, action: PayloadAction<CheckoutUserInterface>) {
            state.parentUser = action.payload;
        },
        resetUserState() {
            return initialState;
        }
    }
});

export const { setSelectedStudent, setStudent, setParentUser, resetUserState } =
    userSlice.actions;

export default userSlice.reducer;
