import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import { ValidateCouponResponseInterface } from "@/ts/interfaces/Checkout/api/ValidateCouponResponseInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    addAddon,
    addSubjectChanges,
    createPaymentIntentForAddon,
    createPaymentIntentForSubjectChanges,
    createSubscription,
    postCreatePaymentIntent,
    validateCoupon
} from "../checkoutThunks";

interface PaymentState {
    isPaymentPending: boolean;
    couponDetails: ValidateCouponResponseInterface | null;
    validateCouponRequestError: string | null;

    validateCouponRequestStatus: REQUEST_STATUS;
    createPaymentIntentRequestStatus: REQUEST_STATUS;
    createSubscriptionRequestStatus: REQUEST_STATUS;
    addAddonRequestStatus: REQUEST_STATUS;
    addSubjectChangesRequestStatus: REQUEST_STATUS;
    createPaymentIntentForAddonRequestStatus: REQUEST_STATUS;
    createPaymentIntentForSubjectChangesRequestStatus: REQUEST_STATUS;
}

const initialState: PaymentState = {
    isPaymentPending: false,
    couponDetails: null,
    validateCouponRequestError: null,

    validateCouponRequestStatus: REQUEST_STATUS.IDLE,
    createPaymentIntentRequestStatus: REQUEST_STATUS.IDLE,
    createSubscriptionRequestStatus: REQUEST_STATUS.IDLE,
    addAddonRequestStatus: REQUEST_STATUS.IDLE,
    addSubjectChangesRequestStatus: REQUEST_STATUS.IDLE,
    createPaymentIntentForAddonRequestStatus: REQUEST_STATUS.IDLE,
    createPaymentIntentForSubjectChangesRequestStatus: REQUEST_STATUS.IDLE
};

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        setIsPaymentPending(state, action: PayloadAction<boolean>) {
            state.isPaymentPending = action.payload;
        },
        resetValidateCouponRequestStatus(state) {
            state.validateCouponRequestStatus = REQUEST_STATUS.IDLE;
            state.couponDetails = null;
            state.validateCouponRequestError = null;
        },
        resetPaymentState() {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            // Validate coupon code
            .addCase(validateCoupon.pending, state => {
                state.validateCouponRequestStatus = REQUEST_STATUS.PENDING;
                state.validateCouponRequestError = null;
            })
            .addCase(validateCoupon.fulfilled, (state, action) => {
                state.validateCouponRequestStatus = REQUEST_STATUS.SUCCESS;
                state.couponDetails = action.payload;
            })
            .addCase(validateCoupon.rejected, (state, action) => {
                state.validateCouponRequestStatus = REQUEST_STATUS.ERROR;
                state.couponDetails = null;
                state.validateCouponRequestError = action.payload as string;
            })

            // Create payment intent
            .addCase(postCreatePaymentIntent.pending, state => {
                state.createPaymentIntentRequestStatus = REQUEST_STATUS.PENDING;
            })
            .addCase(postCreatePaymentIntent.fulfilled, state => {
                state.createPaymentIntentRequestStatus = REQUEST_STATUS.SUCCESS;
            })
            .addCase(postCreatePaymentIntent.rejected, state => {
                state.createPaymentIntentRequestStatus = REQUEST_STATUS.ERROR;
            })

            // Create subscription
            .addCase(createSubscription.pending, state => {
                state.createSubscriptionRequestStatus = REQUEST_STATUS.PENDING;
            })
            .addCase(createSubscription.fulfilled, state => {
                state.createSubscriptionRequestStatus = REQUEST_STATUS.SUCCESS;
            })
            .addCase(createSubscription.rejected, state => {
                state.createSubscriptionRequestStatus = REQUEST_STATUS.ERROR;
            })

            // Add addon
            .addCase(addAddon.pending, state => {
                state.addAddonRequestStatus = REQUEST_STATUS.PENDING;
            })
            .addCase(addAddon.fulfilled, state => {
                state.addAddonRequestStatus = REQUEST_STATUS.SUCCESS;
            })
            .addCase(addAddon.rejected, state => {
                state.addAddonRequestStatus = REQUEST_STATUS.ERROR;
            })

            // Add subject changes
            .addCase(addSubjectChanges.pending, state => {
                state.addSubjectChangesRequestStatus = REQUEST_STATUS.PENDING;
            })
            .addCase(addSubjectChanges.fulfilled, state => {
                state.addSubjectChangesRequestStatus = REQUEST_STATUS.SUCCESS;
            })
            .addCase(addSubjectChanges.rejected, state => {
                state.addSubjectChangesRequestStatus = REQUEST_STATUS.ERROR;
            })

            // Create payment intent for addon
            .addCase(createPaymentIntentForAddon.pending, state => {
                state.createPaymentIntentForAddonRequestStatus =
                    REQUEST_STATUS.PENDING;
            })
            .addCase(createPaymentIntentForAddon.fulfilled, state => {
                state.createPaymentIntentForAddonRequestStatus =
                    REQUEST_STATUS.SUCCESS;
            })
            .addCase(createPaymentIntentForAddon.rejected, state => {
                state.createPaymentIntentForAddonRequestStatus =
                    REQUEST_STATUS.ERROR;
            })

            // Create payment intent for subject changes
            .addCase(createPaymentIntentForSubjectChanges.pending, state => {
                state.createPaymentIntentForSubjectChangesRequestStatus =
                    REQUEST_STATUS.PENDING;
            })
            .addCase(createPaymentIntentForSubjectChanges.fulfilled, state => {
                state.createPaymentIntentForSubjectChangesRequestStatus =
                    REQUEST_STATUS.SUCCESS;
            })
            .addCase(createPaymentIntentForSubjectChanges.rejected, state => {
                state.createPaymentIntentForSubjectChangesRequestStatus =
                    REQUEST_STATUS.ERROR;
            });
    }
});

export const {
    resetValidateCouponRequestStatus,
    setIsPaymentPending,
    resetPaymentState
} = paymentSlice.actions;

export default paymentSlice.reducer;
